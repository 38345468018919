import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SECURITY_FEE, propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  calculateLineItemsTotal,
  getNonCommissionNonReversalLineItems,
} from './LineItemRefundMaybe';
import { useSelector } from 'react-redux';
import { claimListingSelectors } from '../../ducks/global.duck';

import css from './OrderBreakdown.module.css';
import { getSecurityRefundedAmount } from '../../util/dataExtractors';

const { Money } = sdkTypes;

const LineItemTotalPrice = ({
  transaction,
  isProvider,
  lineItems,
  intl,
  refundPercentage = 0,
  marketplaceCurrency,
  txPayoutTotalMaybe,
  setTxPayoutTotalMaybe,
}) => {
  const { providerCommissionPercentage } = useSelector(claimListingSelectors);
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);

  if (!processName) return null;

  const process = getProcess(processName);
  const { lastTransition } = transaction.attributes;
  const isCompleted = process.isCompleted(lastTransition);
  const isRefunded = process.isRefunded(lastTransition);

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  const securityLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SECURITY_FEE && !item.reversal
  )?.lineTotal;

  const securityAmountAfterRefund = getSecurityRefundedAmount(transaction);
  const finalSecurityAmount =
    securityAmountAfterRefund > 0
      ? securityAmountAfterRefund
      : securityLineItem?.amount > 0
      ? securityLineItem?.amount
      : 0;

  const baseLineItems = getNonCommissionNonReversalLineItems(lineItems);
  const basePriceObject = calculateLineItemsTotal(baseLineItems, marketplaceCurrency);
  const basePrice = parseFloat(basePriceObject.amount || 0);
  const refundLineItems = getNonCommissionNonReversalLineItems(lineItems);
  const refund = calculateLineItemsTotal(refundLineItems, marketplaceCurrency);
  const baseRefundPrice = parseFloat(isRefunded ? refund.amount : 0);

  const calculatePercentageAmount = (totalAmount, percentage) => {
    return Math.round((totalAmount * percentage) / 100);
  };
  const refundAmount =
    refundPercentage > 0 ? (basePrice * refundPercentage) / 100 : baseRefundPrice;
  const withoutCommissionAmount = basePrice - refundAmount;
  const providerCommissionAmount = calculatePercentageAmount(
    withoutCommissionAmount,
    providerCommissionPercentage
  );
  const finalAmount = isProvider
    ? withoutCommissionAmount - providerCommissionAmount
    : withoutCommissionAmount;
  const finalPrice = new Money(finalAmount || 0, marketplaceCurrency);
  const formattedFinalPrice = formatMoney(intl, finalPrice);

  const priceWithoutSecurityAmount =
    finalAmount > 0
      ? new Money(finalAmount - finalSecurityAmount, marketplaceCurrency)
      : new Money(0, marketplaceCurrency);
  const formattedPriceWithoutSecurityAmount = formatMoney(intl, priceWithoutSecurityAmount);
  const formattedPrice =
    finalPrice?.amount > 0 && !formattedPriceWithoutSecurityAmount
      ? formattedFinalPrice
      : isProvider
      ? formattedPriceWithoutSecurityAmount
      : formattedFinalPrice;
  useEffect(() => {
    if (!txPayoutTotalMaybe && formattedPrice) {
      setTxPayoutTotalMaybe(formattedPrice);
    }
  }, [txPayoutTotalMaybe, formattedPrice, setTxPayoutTotalMaybe]);

  const providerTotalMessageId = isCompleted
    ? 'OrderBreakdown.providerTotalReceived'
    : isRefunded
    ? 'OrderBreakdown.providerTotalRefunded'
    : finalSecurityAmount > 0 && priceWithoutSecurityAmount
    ? 'OrderBreakdown.providerTotalDefault'
    : 'OrderBreakdown.providerTotalProfit';

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  return (
    <>
      <hr className={css.totalDivider} />
      {securityAmountAfterRefund && isProvider ? (
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>
            <FormattedMessage id="OrderBreakdown.securityDepositFeeRefund" />{' '}
          </div>
          <div className={css.totalPrice}>-${(securityAmountAfterRefund / 100).toFixed(2)}</div>
        </div>
      ) : null}
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
