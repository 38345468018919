import React from 'react';
import PropTypes from 'prop-types';
import css from './StrapiContentRenderer.css';

// Block Components
const Paragraph = ({ children }) => <p>{children}</p>;
const Heading = ({ level, children }) => {
  const Tag = `h${level}`;
  return <Tag>{children}</Tag>;
};

const List = ({ format, children }) => {
  const Tag = format === 'ordered' ? 'ol' : 'ul';
  return <Tag>{children}</Tag>;
};

const Quote = ({ children }) => <blockquote>{children}</blockquote>;
const CodeBlock = ({ plainText }) => (
  <pre>
    <code>{plainText}</code>
  </pre>
);

const Image = ({ src, alt }) => <img src={src} alt={alt} />;
const Link = ({ url, children }) => <a href={url}>{children}</a>;

// Inline Modifier Components
const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <em>{children}</em>;
const Underline = ({ children }) => <u>{children}</u>;
const Strikethrough = ({ children }) => <s>{children}</s>;
const InlineCode = ({ children }) => <code>{children}</code>;

// Render Inline Modifiers
const renderInlineModifiers = (fragments) => {
  if (!Array.isArray(fragments)) return fragments;

  return fragments.map((fragment, index) => {
    if (typeof fragment === 'string') return fragment;

    // Handle text fragment with multiple styles
    if (fragment.type === 'text') {
      let content = fragment.text;

      // Wrap content in style components based on properties
      if (fragment.bold) {
        content = <Bold key={`bold-${index}`}>{content}</Bold>;
      }
      if (fragment.italic) {
        content = <Italic key={`italic-${index}`}>{content}</Italic>;
      }
      if (fragment.underline) {
        content = <Underline key={`underline-${index}`}>{content}</Underline>;
      }

      return content;
    }

    // Handle nested modifiers (e.g., 'bold' wrapping 'italic')
    switch (fragment.type) {
      case 'bold':
        return <Bold key={index}>{renderInlineModifiers(fragment.children)}</Bold>;
      case 'italic':
        return <Italic key={index}>{renderInlineModifiers(fragment.children)}</Italic>;
      case 'underline':
        return <Underline key={index}>{renderInlineModifiers(fragment.children)}</Underline>;
      case 'strikethrough':
        return <Strikethrough key={index}>{renderInlineModifiers(fragment.children)}</Strikethrough>;
      case 'code':
        return <InlineCode key={index}>{renderInlineModifiers(fragment.children)}</InlineCode>;
      default:
        return fragment.text; // Render plain text
    }
  });
};

// Render JSON Nodes
const renderNode = (node) => {
  if (!node) return null;

  switch (node.type) {
    case 'paragraph':
      return (
        <Paragraph key={node.id}>
          {node.children && node.children.map(renderNode)}
        </Paragraph>
      );
    case 'heading':
      return (
        <Heading key={node.id} level={node.level}>
          {node.children && node.children.map(renderNode)}
        </Heading>
      );
    case 'list':
      return (
        <List key={node.id} format={node.format}>
          {node.children && node.children.map(renderNode)}
        </List>
      );
    case 'list-item':
      return (
        <li key={node.id}>
          {node.children && node.children.map(renderNode)}
        </li>
      );
    case 'quote':
      return (
        <Quote key={node.id}>
          {node.children && node.children.map(renderNode)}
        </Quote>
      );
    case 'code':
      return <CodeBlock key={node.id} plainText={node.plainText} />;
    case 'image':
      return <Image key={node.id} src={node.image.src} alt={node.image.alt} />;
    case 'link':
      return (
        <Link key={node.id} url={node.url}>
          {node.children && node.children.map(renderNode)}
        </Link>
      );
    case 'text':
      return renderInlineModifiers([node]); // Process single text node with modifiers
    default:
      return null;
  }
};

// Parse Markdown Content
const parseMarkdown = (text) => {
  const inlineStyles = [
    { regex: /\*\*(.*?)\*\*/g, tag: (content) => <Bold>{content}</Bold> },
    { regex: /_(.*?)_/g, tag: (content) => <Italic>{content}</Italic> },
    { regex: /`(.*?)`/g, tag: (content) => <InlineCode>{content}</InlineCode> },
    { regex: /~~(.*?)~~/g, tag: (content) => <Strikethrough>{content}</Strikethrough> },
  ];

  return text.split('\n').map((line, index) => {
    // Handle headings
    const headingMatch = line.match(/^(#+)\s+(.*)$/);
    if (headingMatch) {
      const level = headingMatch[1].length; // Number of `#` determines heading level
      const content = headingMatch[2];
      return React.createElement(`h${level}`, { key: index }, content);
    }

    // Handle links
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
    if (linkRegex.test(line)) {
      const parts = line.split(linkRegex).filter(Boolean);
      return (
        <p key={index}>
          {parts.map((part, i) =>
            i % 3 === 1 ? (
              <a key={i} href={part} target="_blank" rel="noopener noreferrer">
                {parts[i - 1]}
              </a>
            ) : (
              part
            )
          )}
        </p>
      );
    }

    // Apply inline styles
    let formattedLine = line;
    inlineStyles.forEach(({ regex, tag }) => {
      formattedLine = formattedLine.replace(regex, (_, match) => {
        return React.createElement(tag, { key: match }, match);
      });
    });

    // Handle bullet lists
    if (line.startsWith('- ') || line.startsWith('* ')) {
      return <li key={index}>{formattedLine.slice(2)}</li>;
    }

    // Default to rendering as a paragraph
    return <Paragraph key={index}>{formattedLine}</Paragraph>;
  });
};

// Main Component
const StrapiContentRenderer = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={css.contentRenderer}>
      {content.map((block) => {
        if (typeof block === 'string') {
          return parseMarkdown(block).map((node) => node);
        }
        // Render JSON nodes
        return renderNode(block);
      })}
    </div>
  );
};

StrapiContentRenderer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
    .isRequired,
};

export default StrapiContentRenderer;
