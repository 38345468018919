import { fetchCurrentUser } from '../../ducks/user.duck';
import { DashboadPageNavOption, StatusCode } from '../../util/enums';

import { denormalisedEntities, updatedEntities } from '../../util/data';
import { entityRefs } from '../../util/genericHelpers';
import {
  getNumberOfNightsBooked,
  getPayoutTotal,
  getPayoutDate,
  getReservationDate,
  getUserFullName,
} from '../../util/dataExtractors';
import moment from 'moment';
import { parse } from '../../util/urlHelpers';
import { storableError } from '../../util/errors';

const RESULTS_PER_PAGE = 100;
const PAGE = 1;

// ================ Action types ================ //

export const USER_DETAILS_REQUEST = 'app/DashboardPage/USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'app/DashboardPage/USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'app/DashboardPage/USER_DETAILS_ERROR';

export const RECENT_LISTINGS_REQUEST = 'app/DashboardPage/RECENT_LISTINGS_REQUEST';
export const RECENT_LISTINGS_SUCCESS = 'app/DashboardPage/RECENT_LISTINGS_SUCCESS';
export const RECENT_LISTINGS_ERROR = 'app/DashboardPage/RECENT_LISTINGS_ERROR';

export const RECENT_MESSAGES_REQUEST = 'app/DashboardPage/RECENT_MESSAGES_REQUEST';
export const RECENT_MESSAGES_SUCCESS = 'app/DashboardPage/RECENT_MESSAGES_SUCCESS';
export const RECENT_MESSAGES_ERROR = 'app/DashboardPage/RECENT_MESSAGES_ERROR';

export const RECENT_TRANSACTIONS_REQUEST = 'app/DashboardPage/RECENT_TRANSACTIONS_REQUEST';
export const RECENT_TRANSACTIONS_SUCCESS = 'app/DashboardPage/RECENT_TRANSACTIONS_SUCCESS';
export const RECENT_TRANSACTIONS_ERROR = 'app/DashboardPage/RECENT_TRANSACTIONS_ERROR';

export const TRANSACTION_TRANSITION_REQUEST = 'app/DashboardPage/TRANSACTION_TRANSITION_REQUEST';
export const TRANSACTION_TRANSITION_SUCCESS = 'app/DashboardPage/TRANSACTION_TRANSITION_SUCCESS';
export const TRANSACTION_TRANSITION_ERROR = 'app/DashboardPage/TRANSACTION_TRANSITION_ERROR';

export const HOST_STRIPE_DETAILS_REQUEST = 'app/DashboardPage/HOST_STRIPE_DETAILS_REQUEST';
export const HOST_STRIPE_DETAILS_SUCCESS = 'app/DashboardPage/HOST_STRIPE_DETAILS_SUCCESS';
export const HOST_STRIPE_DETAILS_ERROR = 'app/DashboardPage/HOST_STRIPE_DETAILS_ERROR';

export const HOST_LISTINGS_SUMMARY_REQUEST = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_REQUEST';
export const HOST_LISTINGS_SUMMARY_SUCCESS = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_SUCCESS';
export const HOST_LISTINGS_SUMMARY_ERROR = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_ERROR';

// ================ Reducer ================ //

const initialState = {
  userDetailsInProgress: false,
  userDetailsError: null,
  userDetails: [],
  metadata: {},

  recentListingsInProgress: false,
  recentListingsError: null,
  recentListings: [],

  recentMessagesInProgress: false,
  recentMessagesError: null,
  recentMessages: [],

  recentTransactionsInProgress: false,
  recentTransactionsError: null,
  recentTransactions: [],

  transactionTransitionInProgress: false,
  transactionTransitionError: null,

  hostStripeDetailsInProgress: false,
  hostStripeDetailsError: null,
  hostStripeDetails: [],

  hostListingsSummaryInProgress: false,
  hostListingsSummaryError: null,
  hostListingsSummary: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailsInProgress: true,
        userDetailsError: null,
        userDetails: [],
        metadata: {},
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetailsInProgress: false,
        userDetails: payload.userData,
        metadata: payload.metadata,
      };
    case USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsInProgress: false,
        userDetailsError: payload,
        userDetails: [],
        metadata: {},
      };
    case HOST_STRIPE_DETAILS_REQUEST:
      return {
        ...state,
        hostStripeDetailsInProgress: true,
        hostStripeDetailsError: null,
        hostStripeDetails: [],
        metadata: {},
      };
    case HOST_STRIPE_DETAILS_SUCCESS:
      return {
        ...state,
        hostStripeDetailsInProgress: false,
        hostStripeDetailsError: null,
        hostStripeDetails: payload.hostStripeDetails,
        metadata: payload.metadata,
      };
    case HOST_STRIPE_DETAILS_ERROR:
      return {
        ...state,
        hostStripeDetailsInProgress: false,
        hostStripeDetailsError: payload,
        hostStripeDetails: [],
        metadata: {},
      };
    case HOST_LISTINGS_SUMMARY_REQUEST:
      return {
        ...state,
        hostListingsSummaryInProgress: true,
        hostListingsSummaryError: null,
        hostListingsSummary: [],
        metadata: {},
      };
    case HOST_LISTINGS_SUMMARY_SUCCESS:
      return {
        ...state,
        hostListingsSummaryInProgress: false,
        hostListingsSummaryError: null,
        hostListingsSummary: payload.hostListingsSummary,
        metadata: payload.metadata,
      };
    case HOST_LISTINGS_SUMMARY_ERROR:
      return {
        ...state,
        hostListingsSummaryInProgress: false,
        hostListingsSummaryError: payload,
        hostListingsSummary: [],
        metadata: {},
      };
    case RECENT_LISTINGS_REQUEST:
      return {
        ...state,
        recentListingsInProgress: true,
        recentListingsError: null,
        recentListings: [],
        metadata: {},
      };
    case RECENT_LISTINGS_SUCCESS:
      return {
        ...state,
        recentListingsInProgress: false,
        recentListingsError: null,
        recentListings: payload.listings,
        metadata: payload.metadata,
      };
    case RECENT_LISTINGS_ERROR:
      return {
        ...state,
        recentListingsInProgress: false,
        recentListingsError: payload,
        recentListings: [],
        metadata: {},
      };
    case RECENT_MESSAGES_REQUEST:
      return {
        ...state,
        recentMessagesInProgress: true,
        recentMessagesError: null,
        recentMessages: [],
        metadata: {},
      };
    case RECENT_MESSAGES_SUCCESS:
      return {
        ...state,
        recentMessagesInProgress: false,
        recentMessagesError: null,
        recentMessages: payload.messages,
        metadata: payload.metadata,
      };
    case RECENT_MESSAGES_ERROR:
      return {
        ...state,
        recentMessagesInProgress: false,
        recentMessagesError: payload,
        recentMessages: [],
        metadata: {},
      };
    case RECENT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        recentTransactionsInProgress: true,
        recentTransactionsError: null,
        recentTransactions: [],
        metadata: {},
      };
    case RECENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        recentTransactionsInProgress: false,
        recentTransactionsError: null,
        recentTransactions: payload.transactions,
        metadata: payload.metadata,
      };
    case RECENT_TRANSACTIONS_ERROR:
      return {
        ...state,
        recentTransactionsInProgress: false,
        recentTransactionsError: payload,
        recentTransactions: [],
        metadata: {},
      };
    case TRANSACTION_TRANSITION_REQUEST:
      return {
        ...state,
        transactionTransitionInProgress: true,
        transactionTransitionError: null,
      };
    case TRANSACTION_TRANSITION_SUCCESS:
      return {
        ...state,
        transactionTransitionInProgress: false,
        transactionTransitionError: null,
      };
    case TRANSACTION_TRANSITION_ERROR:
      return {
        ...state,
        transactionTransitionInProgress: false,
        transactionTransitionError: payload,
      };

    default:
      return state;
  }
}
// ================ Selectors ================ //

export const userDetailsSelector = state => {
  const { userDetails, userDetailsInProgress, userDetailsError, metadata } = state.DashboardPage;
  return {
    userDetails,
    userDetailsInProgress,
    userDetailsError,
    metadata,
  };
};
export const hostStripeDetailsSelector = state => {
  const {
    hostStripeDetails,
    hostStripeDetailsInProgress,
    hostStripeDetailsError,
    metadata,
  } = state.DashboardPage;
  return {
    hostStripeDetails,
    hostStripeDetailsInProgress,
    hostStripeDetailsError,
    metadata,
  };
};
export const hostListingsSummarySelector = state => {
  const {
    hostListingsSummaryInProgress,
    hostListingsSummaryError,
    hostListingsSummary,
    metadata,
  } = state.DashboardPage;
  return {
    hostListingsSummaryInProgress,
    hostListingsSummaryError,
    hostListingsSummary,
    metadata,
  };
};
export const recentListingsSelector = state => {
  const {
    recentListings,
    recentListingsInProgress,
    recentListingsError,
    metadata,
  } = state.DashboardPage;
  return {
    metadata,
    recentListings,
    recentListingsInProgress,
    recentListingsError,
  };
};
export const recentMessagesSelector = state => {
  const {
    recentMessagesInProgress,
    recentMessagesError,
    recentMessages,
    metadata,
  } = state.DashboardPage;
  return {
    metadata,
    recentMessagesInProgress,
    recentMessagesError,
    recentMessages,
  };
};
export const recentTransactionsSelector = state => {
  const {
    recentTransactionsInProgress,
    recentTransactionsError,
    recentTransactions,
    metadata,
  } = state.DashboardPage;
  return {
    recentTransactionsInProgress,
    recentTransactionsError,
    recentTransactions,
    metadata,
  };
};
export const transactionTransitionSelector = state => {
  const { transactionTransitionInProgress, transactionTransitionError } = state.DashboardPage;
  return {
    transactionTransitionInProgress,
    transactionTransitionError,
  };
};

// ================ Action creators ================ //

export const userDetailsRequest = () => ({ type: USER_DETAILS_REQUEST });

export const userDetailsSuccess = (userData, metadata) => ({
  type: USER_DETAILS_SUCCESS,
  payload: { userData, metadata },
});

export const userDetailsError = e => ({
  type: USER_DETAILS_ERROR,
  error: true,
  payload: e,
});
export const hostStripeDetailsRequest = () => ({ type: HOST_STRIPE_DETAILS_REQUEST });

export const hostStripeDetailsSuccess = (hostStripeDetails, metadata) => ({
  type: HOST_STRIPE_DETAILS_SUCCESS,
  payload: { hostStripeDetails, metadata },
});

export const hostStripeDetailsError = e => ({
  type: HOST_STRIPE_DETAILS_ERROR,
  error: true,
  payload: e,
});
export const hostListingsSummaryRequest = () => ({ type: HOST_LISTINGS_SUMMARY_REQUEST });

export const hostListingsSummarySuccess = (hostListingsSummary, metadata) => ({
  type: HOST_LISTINGS_SUMMARY_SUCCESS,
  payload: { hostListingsSummary, metadata },
});

export const hostListingsSummaryError = e => ({
  type: HOST_LISTINGS_SUMMARY_ERROR,
  error: true,
  payload: e,
});
export const recentListingsRequest = () => ({ type: RECENT_LISTINGS_REQUEST });

export const recentListingsSuccess = (listings, metadata) => ({
  type: RECENT_LISTINGS_SUCCESS,
  payload: { listings, metadata },
});

export const recentListingsError = e => ({
  type: RECENT_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const recentMessagesRequest = () => ({ type: RECENT_MESSAGES_REQUEST });

export const recentMessagesSuccess = (messages, metadata) => ({
  type: RECENT_MESSAGES_SUCCESS,
  payload: { messages, metadata },
});

export const recentMessagesError = e => ({
  type: RECENT_MESSAGES_ERROR,
  error: true,
  payload: e,
});
export const recentTransactionsRequest = () => ({ type: RECENT_TRANSACTIONS_REQUEST });

export const recentTransactionsSuccess = (transactions, metadata) => {
  return {
    type: RECENT_TRANSACTIONS_SUCCESS,
    payload: { transactions, metadata },
  };
};

export const recentTransactionsError = e => ({
  type: RECENT_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});
export const transactionTransitionRequest = () => ({ type: TRANSACTION_TRANSITION_REQUEST });

export const transactionTransitionSuccess = () => ({
  type: TRANSACTION_TRANSITION_SUCCESS,
});

export const transactionTransitionError = e => ({
  type: TRANSACTION_TRANSITION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchRecentTransactions = params => async (dispatch, getState, sdk) => {
  dispatch(recentTransactionsRequest());

  // Set default date filters
  const createdAtStart =
    params?.createdAtStart ||
    moment()
      .startOf('month')
      .toISOString();
  const createdAtEnd = params?.createdAtEnd || moment().toISOString();
  const lastTransitions = params?.lastTransition || null;
  try {
    const { data = {} } = await sdk.transactions.query({
      page: params?.page || 1,
      perPage: 100,
      include: [
        'customer',
        'customer.profileImage',
        'provider',
        'provider.profileImage',
        'listing',
        'listing.currentStock',
        'booking',
        'reviews',
        'reviews.author',
        'reviews.subject',
      ],
      // Include the date filters in the query
      createdAtStart,
      createdAtEnd,
      lastTransitions,
    });
    const { meta = {} } = data || {};
    const entities = updatedEntities({}, data);
    const listingsRefs = entityRefs(data.data);
    const denormalisedTransactions = denormalisedEntities(entities, listingsRefs, false);
    const desiredTransactionsData =
      denormalisedTransactions?.length > 0
        ? denormalisedTransactions.map(transaction => {
            return {
              transactionId: transaction?.id?.uuid,
              listingTitle: transaction?.listing?.attributes?.title,
              guestName: getUserFullName(transaction?.customer),
              reservationDate: getReservationDate(transaction?.booking),
              payoutDate: getPayoutDate(transaction?.booking),
              nightsBooked: getNumberOfNightsBooked(transaction?.booking),
              customerPaidAmount: getPayoutTotal(transaction),
              lastTransition: transaction?.attributes?.lastTransition,
            };
          })
        : [];
    const filteredTransactions = desiredTransactionsData?.filter(
      transaction => transaction.listingTitle !== null
    );

    dispatch(recentTransactionsSuccess(filteredTransactions, meta));
  } catch (error) {
    dispatch(recentTransactionsError(storableError(error)));
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const tab = params?.tab;
  const { page = 1, endDate = null, startDate = null, sku = null, status = null } =
    parse(search) || {};

  if (startDate && endDate) {
    Object.assign(params, {
      createdAtStart: new Date(startDate).toISOString(),
      createdAtEnd: new Date(endDate).toISOString(),
    });
  }

  // Map tab options to actions and execute the appropriate one
  const tabActions = new Map([
    [DashboadPageNavOption.TRANSACTIONS_DETAILS, () => dispatch(fetchRecentTransactions(params))],
  ]);
  const action = tabActions.get(tab);
  if (action) {
    await action();
  }

  return await dispatch(fetchCurrentUser());
};
