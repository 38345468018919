import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemExtendBookingPriceMaybe = props => {
  const { intl, totalAmount = 0, start, end, lineItems, isProvider, status } = props;

  const formattedExtendedPrice = new Money(totalAmount, 'USD');
  const total = formattedExtendedPrice ? formatMoney(intl, formattedExtendedPrice) : null;

  const isDeclined = status === 'declined';

  return total ? (
    <div className={`${css.lineItem} ${isDeclined ? css.declinedBackground : ''}`}>
      {isDeclined && (
        <span className={css.declinedText}>
          <FormattedMessage id="OrderBreakdown.declinedText" defaultMessage="Declined" />
        </span>
      )}
      <div className={css.iteminfo}>
        <span className={css.itemLabel}>
          <FormattedMessage
            id="OrderBreakdown.extendbookingFrom"
            values={{ extendedStart: start, extendedEnd: end }}
          />
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
    </div>
  ) : null;
};

LineItemExtendBookingPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemExtendBookingPriceMaybe;
