/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',
  DECLINE_BY_CUSTOMER: 'transition/decline-by-customer',

  // After accept cancel transitions
  CANCEL_BY_CUSTOMER_AFTER_ACCEPTED: 'transition/cancel-by-customer-after-accepted',
  CANCEL_BY_PROVIDER_AFTER_ACCEPTED: 'transition/cancel-by-provider-after-accepted',

  // After Proider contract singed cancel transitions
  CANCEL_BY_PROVIDER_AFTER_PROVIDER_SINGED: 'transition/cancel-by-provider-after-provider-signed',
  CANCEL_BY_CUSTOMER_AFTER_PROVIDER_SINGED: 'transition/cancel-by-customer-after-provider-signed',

  // After Proider contract singed cancel transitions
  CANCEL_BY_PROVIDER_AFTER_CUSTOMER_SINGED: 'transition/cancel-by-provider-after-customer-signed',
  CANCEL_BY_CUSTOMER_AFTER_CUSTOMER_SINGED: 'transition/cancel-by-customer-after-customer-signed',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  // Contract transitions.
  SIGNED_BY_PROVIDER: 'transition/contract-signed-by-provider',
  NO_CONTRACT_NEEDED_BY_PROVIDER: 'transition/no-contract-needed-by-provider',
  SIGNED_BY_CUSTOMER: 'transition/contract-signed-by-customer',

  // Extend booking.
  EXTEND_BOOKING: 'transition/extend-booking',
  ACCEPT_EXTEND_BOOKING: 'transition/accept-extend-booking',
  DECLINE_EXTEND_BOOKING: 'transition/decline-extend-booking',
  COMPLETE_AFTER_EXTEND_BOOKING: 'transition/complete-after-extend',

  // security refund transitions
  REFUND_SECURITY_DEPOSIT: 'transition/refund-security-deposit',
  REQUEST_DAMAGE_CLAIM: 'transition/request-damage-claim',

  // operator claim accept or decline transitions
  ACCEPT_DAMAGE_CLAIM: 'transition/accept-damage-claim',
  DECLINE_DAMAGE_CLAIM_FULL_REFUND: 'transition/decline-damage-claim-full-refund-to-customer',
  AUTO_REFUND_FULL_SECURITY_DEPOSIT: 'transition/auto-refund-full-security-deposit',
  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  PROVIDER_SIGNED: 'provider-signed',
  CUSTOMER_SIGNED: 'customer-signed',
  BOOKING_EXTENDED: 'booking-extended',
  DELIVERED: 'delivered',
  REFUND: 'refund',
  REFUND_REQUESTED: 'refund-requested',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.DECLINE]: states.DECLINED,
        [transitions.DECLINE_BY_CUSTOMER]: states.DECLINED,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.OPERATOR_ACCEPT]: states.ACCEPTED,
      },
    },

    [states.DECLINED]: {},
    [states.EXPIRED]: {},

    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED]: states.CANCELED,
        [transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED]: states.CANCELED,
        [transitions.SIGNED_BY_PROVIDER]: states.PROVIDER_SIGNED,
        [transitions.NO_CONTRACT_NEEDED_BY_PROVIDER]: states.CUSTOMER_SIGNED,
        [transitions.CANCEL]: states.CANCELED,
      },
    },

    [states.PROVIDER_SIGNED]: {
      on: {
        [transitions.SIGNED_BY_CUSTOMER]: states.CUSTOMER_SIGNED,
        [transitions.CANCEL_BY_CUSTOMER_AFTER_PROVIDER_SINGED]: states.CANCELED,
        [transitions.CANCEL_BY_PROVIDER_AFTER_PROVIDER_SINGED]: states.CANCELED,
      },
    },

    [states.CUSTOMER_SIGNED]: {
      on: {
        [transitions.CANCEL_BY_CUSTOMER_AFTER_CUSTOMER_SINGED]: states.CANCELED,
        [transitions.CANCEL_BY_PROVIDER_AFTER_CUSTOMER_SINGED]: states.CANCELED,
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
        [transitions.EXTEND_BOOKING]: states.BOOKING_EXTENDED,
      },
    },

    [states.BOOKING_EXTENDED]: {
      on: {
        [transitions.ACCEPT_EXTEND_BOOKING]: states.CUSTOMER_SIGNED,
        [transitions.DECLINE_EXTEND_BOOKING]: states.CUSTOMER_SIGNED,
        [transitions.COMPLETE_AFTER_EXTEND_BOOKING]: states.DELIVERED,
      },
    },

    [states.CANCELED]: {},
    [states.DELIVERED]: {
      on: {
        [transitions.REFUND_SECURITY_DEPOSIT]: states.REFUND,
        [transitions.REQUEST_DAMAGE_CLAIM]: states.REFUND_REQUESTED,
      },
    },

    [states.REFUND_REQUESTED]: {
      on: {
        [transitions.ACCEPT_DAMAGE_CLAIM]: states.REFUND,
        [transitions.DECLINE_DAMAGE_CLAIM_FULL_REFUND]: states.REFUND,
      },
    },

    [states.REFUND]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.OPERATOR_ACCEPT,
    transitions.CANCEL,
    transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.SIGNED_BY_PROVIDER,
    transitions.NO_CONTRACT_NEEDED_BY_PROVIDER,
    transitions.CANCEL_BY_CUSTOMER_AFTER_PROVIDER_SINGED,
    transitions.CANCEL_BY_PROVIDER_AFTER_PROVIDER_SINGED,
    transitions.SIGNED_BY_CUSTOMER,
    transitions.EXTEND_BOOKING,
    transitions.ACCEPT_EXTEND_BOOKING,
    transitions.DECLINE_EXTEND_BOOKING,
    transitions.COMPLETE_AFTER_EXTEND_BOOKING,
    transitions.CANCEL_BY_CUSTOMER_AFTER_CUSTOMER_SINGED,
    transitions.CANCEL_BY_PROVIDER_AFTER_CUSTOMER_SINGED,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.DECLINE_BY_CUSTOMER,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.REFUND_SECURITY_DEPOSIT,
    transitions.REQUEST_DAMAGE_CLAIM,
    transitions.AUTO_REFUND_FULL_SECURITY_DEPOSIT,
    transitions.DECLINE_DAMAGE_CLAIM_FULL_REFUND,
    transitions.ACCEPT_DAMAGE_CLAIM,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
    transition
  );
};

export const shouldShowContract = (transition, isCustomer) => {
  if (isCustomer) {
    return [transitions.SIGNED_BY_PROVIDER].includes(transition);
  }
  return [transitions.ACCEPT, transitions.OPERATOR_ACCEPT].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.DECLINE,
    transitions.DECLINE_BY_CUSTOMER,
    transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.CANCEL_BY_CUSTOMER_AFTER_PROVIDER_SINGED,
    transitions.CANCEL_BY_PROVIDER_AFTER_PROVIDER_SINGED,
    transitions.CANCEL_BY_PROVIDER_AFTER_CUSTOMER_SINGED,
    transitions.CANCEL_BY_CUSTOMER_AFTER_CUSTOMER_SINGED,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
