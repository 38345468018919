import moment from 'moment';
import { isArrayLength } from './genericHelpers';
import { createSlug } from './urlHelpers';
const { Money } = sdkTypes;
import { types as sdkTypes } from '../util/sdkLoader';
import { LINE_ITEM_SECURITY_FEE } from './types';

export const isInstantBooking = l => {
  return l?.attributes?.publicData?.instantBooking === 'yes';
};

export function generateRandomEmailPrefix(email) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const prefixLength = email.substring(0, email.indexOf('@')).length;
  let randomPrefix = '';
  for (let i = 0; i < prefixLength; i++) {
    randomPrefix += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomPrefix;
}

export const getCustomerEmailParams = t => {
  const listing = t?.listing;
  const booking = t?.booking;
  const bookingStart = moment(booking?.attributes?.start).format('Do MMMM YYYY');
  const bookingEnd = moment(booking?.attributes?.end).format('Do MMMM YYYY');
  const payinTotal = t?.attributes?.payinTotal?.amount / 100;
  const listingTitle = listing?.attributes?.title;
  const address = listing?.attributes?.publicData?.location?.address;

  return { bookingStart, bookingEnd, payinTotal, listingTitle, address };
};

export const isTransactionInstantBooking = t => {
  return t?.attributes?.protectedData?.isInstantBooking;
};

export const isUserGuest = currentUser => {
  return currentUser?.attributes?.email?.includes('test');
};

export const isOwner = currentUser => {
  return currentUser?.attributes?.profile?.publicData?.user_type === 'trailer-owner';
};

export const isListingInfoOnly = listing => {
  return !!listing?.attributes?.publicData?.isInfoOnly;
};

export const shouldShowStripePayoutForInfoOnly = (listing, currentUser) => {
  return (
    listing?.attributes?.state === 'pendingApproval' && !currentUser?.attributes?.stripeConnected
  );
};

export const getTranslations = listing => {
  if (listing?.id?.uuid) {
    const publicData = listing?.attributes?.publicData || {};
    const translations = publicData?.translations?.data || {};
    const fallBackValues = {
      title: listing?.attributes?.title,
      description: listing?.attributes?.description,
      houseRules: publicData?.houseRules,
      tips: publicData?.tips,
    };
    return translations[defaultLocale.toUpperCase()] || fallBackValues;
  } else {
    return null;
  }
};

/**
 * Generates an array of image URLs using the base URL and an array of image objects.
 *
 * @param {Array} images - An array of image objects.
 * @param {string} url - The base URL for the images.
 * @returns {Array} An array of image URLs.
 * @throws {TypeError} If the `images` parameter is not an array.
 */
export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = `${url}${image}`;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};

export const isInfoOnly = l => {
  return !!l?.attributes?.publicData?.lat;
};

export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.title || '';
  const description = blog?.description || '';
  const tags = blog?.tags || '';
  const createdAt = blog?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tags, createdAt, image: imagesLink && imagesLink[0] };
};

export const extractAndFormatBoundsFromString = queryString => {
  // Extract the query string part from the URL
  const queryParamsPart = queryString.split('?')[1];

  // Use URLSearchParams to parse the query parameters
  const params = new URLSearchParams(queryParamsPart);
  const bounds = params.get('bounds');

  // Check if bounds parameter exists
  if (!bounds) {
    console.log('Bounds parameter not found in the URL');
    return null;
  }

  // Split the bounds string into individual coordinates
  const coordinates = bounds.split(',');

  // Ensure we have exactly four coordinates for NE and SW bounds
  if (coordinates.length !== 4) {
    console.log('Invalid bounds format');
    return null;
  }

  // Format the coordinates: NE lat, NE lng, SW lat, SW lng
  const formattedBounds = coordinates.map(coord => coord.trim()).join(',');

  return formattedBounds;
};

export const getLanguageKey = (languageOptions, label) => {
  return languageOptions.find(l => l?.label === label)?.key;
};

export const getAddons = listing => {
  return listing?.attributes?.publicData?.addons;
};

export const getSecurityDepositAmount = l => {
  return l?.attributes?.publicData?.securityDeposit;
};

export const getUserFullName = user => {
  const firstName = user?.attributes?.profile?.firstName || '';
  const lastName = user?.attributes?.profile?.lastName || '';
  const displayName = user?.attributes?.profile?.displayName || '';
  const fullName = displayName || `${firstName} ${lastName}`.trim();

  return fullName || 'N/A';
};

export const createListingUrl = listing => {
  if (!listing?.attributes?.title) return null;
  const id = listing.id.uuid;
  const title = listing?.attributes?.title;
  const slug = createSlug(title);
  return `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/l/${slug}/${id}`;
};

export const getReservationDate = booking => {
  return booking?.attributes?.start || 'N/A';
};

export const getPayoutDate = booking => {
  return booking?.attributes?.end || 'N/A';
};

export const getNumberOfNightsBooked = booking => {
  const startDate = new Date(booking?.attributes?.start);
  const endDate = new Date(booking?.attributes?.end);

  if (!startDate || !endDate) {
    console.error('Invalid dates provided');
    return 0;
  }

  const differenceInTime = endDate - startDate;
  const numberOfNights = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
  return numberOfNights;
};

export const getPayoutTotal = transaction => {
  const payoutTotal = transaction?.attributes?.payoutTotal;
  const lineItems = transaction?.attributes?.lineItems;
  const metadata = transaction?.attributes?.metadata || {};
  if (!payoutTotal) {
    return 'N/A';
  }

  const securityLineItemsItem = lineItems.find(
    item => item.code === LINE_ITEM_SECURITY_FEE && !item.reversal
  )?.lineTotal;
  const securityRefunded = metadata?.securityRefunded || 0;

  let updatedAmount = payoutTotal?.amount;
  let updatedAmountWithExtendedAmount = 0;

  if (securityRefunded && securityRefunded > 0 && payoutTotal?.amount > 0) {
    updatedAmount -= securityRefunded;
  }
  if (!securityRefunded && securityLineItemsItem?.amount > 0 && payoutTotal?.amount > 0) {
    updatedAmount -= securityLineItemsItem?.amount;
  }

  const extentBookingParams = metadata?.extentBookingParams || [];
  if (Array.isArray(extentBookingParams) && extentBookingParams.length > 0 && payoutTotal?.amount > 0) {
    const totalAmountFromParams = extentBookingParams
      .filter(param => param.status !== 'declined') // Exclude declined entries
      .map(param => {
        const totalAmount = param.totalAmount || 0;
        const providerCommission = param.providerCommission || 0;
        const commissionAmount = providerCommission;
        return totalAmount - commissionAmount;
      })
      .reduce((acc, amount) => acc + amount, 0);

    updatedAmountWithExtendedAmount += totalAmountFromParams;
  }

  const finalAmount = updatedAmountWithExtendedAmount > 0 
    ? updatedAmountWithExtendedAmount + updatedAmount 
    : updatedAmount;

  const formattedAmountUpdatedAmount = new Money(finalAmount, payoutTotal.currency);
  return formattedAmountUpdatedAmount;
};
export const getSecurityRefundedAmount = transaction => {
  if (
    transaction &&
    transaction.attributes &&
    transaction.attributes.metadata &&
    transaction.attributes.metadata.securityRefunded
  ) {
    return transaction.attributes.metadata.securityRefunded;
  }
  return null;
};

export const getProviderCommission = (response) => {
  try {
    const providerCommission = response?.data?.data?.[0]?.attributes?.data?.providerCommission;
    return providerCommission || null; // Return providerCommission or null if not found
  } catch (error) {
    console.error("Error extracting providerCommission:", error);
    return null;
  }
}